a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar {
    padding: 0 !important;
}

.navbar-inverse .navbar-brand {
    color: #9d9d9d;
    padding: 0;
}

.navbar-brand {
    padding: 0 !important;
}

.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.trapezoid-logo {
    border-bottom: 50px solid #EEEEEE;
    border-right: 50px solid transparent;
    height: 0;
    width: 251px;
    color: white;
    text-align: center;
    display: inline-block;
}

.trapezoid-logo-span {
    border-bottom: 49px solid #EEEEEE;
    border-right: 25px solid transparent;
    height: 0;
    width: 251px;
    color: white;
    text-align: center;
}

.nav-span-brand {
    color: #fff;
    margin-left: 15px;
    display: inline-block;
    margin-top: 15px;
}

.nav-brand-name {
    display: inline;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.mat-box {
    border-radius: 0px 5px 5px 5px;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
}

.bg-navbar {
    background-image: url('../Images/bglogin.png') !important;
    height: 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.font-navbar {
    font-size: 14px;
    font-family: sans-serif
}