@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Roboto', sans-serif;
}

/* Provide sufficient contrast against white background */
body {
    /*overflow: hidden*/
}

a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.responsive-table {
    overflow: auto;
}

.nowrap {
    white-space: nowrap !important;
}

.Loader {
    height: 100vh;
}

.Loader__background {
    display: none !important;
    z-index: 2000 !important;
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Loader__background.hide {
    display: none !important;
}

.Loader__background.show {
    display: block !important;
    background-color: rgba(247, 247, 247, 0.83) !important;
}

/* * creatable select */
.create-select {
    color: #5f5f5f !important;
    font-size: 14px;
    [class$='ValueContainer'] {
        min-height: 32px !important;
        max-height: 32px;
    }
    [class$='IndicatorsContainer'] {
        min-height: 32px !important;
        max-height: 32px;
    }
    [class$='ValueContainer'] {
        min-height: 32px !important;
        max-height: 32px;
    }
    [class$='-singleValue'] {
        color: #5f5f5f !important;
    }
    /* [class$="-Input"] {
        min-height: 30px !important;
        max-height: 30px;
        padding: 0px;
    } */
    [class$='-control'] {
        min-height: 32px !important;
        max-height: 32px;
    }
}

.create-select-no-border {
    color: #5f5f5f !important;
    font-size: 14px;
    [class$='ValueContainer'] {
        padding: 0;
        min-height: 32px !important;
        max-height: 32px;
    }
    [class$='IndicatorsContainer'] {
        min-height: 32px !important;
        max-height: 32px;
    }
    [class$='ValueContainer'] {
        min-height: 32px !important;
        max-height: 32px;
    }
    [class$='-singleValue'] {
        color: #5f5f5f !important;
    }
    /* [class$="-Input"] {
        min-height: 30px !important;
        max-height: 30px;
        padding: 0px;
    } */
    [class$='-control'] {
        border: none !important;
        box-shadow: none !important;
        min-height: 32px !important;
        max-height: 32px;
    }
}

.select-access-rule {
    color: #7a7a7a !important;
    font-size: 12px;
    font-weight: 300;
    [class$='ValueContainer'] {
        min-height: 28px !important;
        max-height: 28px;
    }
    [class$='IndicatorsContainer'] {
        min-height: 28px !important;
        max-height: 28px;
    }
    [class$='ValueContainer'] {
        min-height: 28px !important;
        max-height: 28px;
    }
    [class$='-singleValue'] {
        color: #5f5f5f !important;
    }
    /* [class$="-Input"] {
        min-height: 30px !important;
        max-height: 30px;
        padding: 0px;
    } */
    [class$='-control'] {
        min-height: 28px !important;
        max-height: 28px;
    }
}
