.row-file-explorer {
    min-height: calc(100vh - 73px);
}

.row-file-explorer .sub-left {}

.row-file-explorer .sub-right {
    /* border-left: 1px solid rgba(0, 0, 0, 0.1); */
}

.folder-file-explorer,
.sub-left,
.sub-right {
    padding-top: 20px;
}

.div-main-style {
    background-color: #f1f1f1;
    height: 100%;
    max-height: calc(100vh - 130px);
    margin-bottom: 0rem;
    overflow: auto;
}

.div-file-component-explorer {
    min-height: calc(100vh - 126px);
}

.div-file-component-search {
    height: calc(100vh - 174px);
}

.file-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
}

.table-main-style {
    border: 0px solid transparent;
}

.table-main-style thead tr th,
.table-main-style thead tr td {
    z-index: 2;
    position: sticky;
    top: -16px;
    background-color: #ececec;
    border-top: 0px solid transparent;
    border-bottom: 2px solid rgba(202, 202, 202, 1);
}

.table-main-style tbody tr:hover {}

.table-main-style td {
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    font-family: sans-serif
}

.table-main-style .td-icon {
    text-align: right;
    width: 60px;
    padding: 8px;
}

.table-main-style .td-file-name {
    cursor: pointer;
}

.table-main-style .td-file-name-version {
    text-align: center
}

.table-main-style .td-icon img {
    max-width: 25px;
}

.table-main-style .td-meta-data {
    width: 110px;
    text-align: center;
}

.table-main-style .td-detail {
    width: 100px;
    text-align: center;
}

.table-main-style .td-path {
    width: 500px;
}

.table-main-style .td-update-on {
    white-space: nowrap;
}

.table-main-style .td-update-by {}

.table-main-style .td-version {
    text-align: center;
}

.table-main-style .td-pi-header-version {
    text-align: center;
    position: sticky;
    top: 0px;
}

.table-main-style .td-pi-list-version {
    text-align: center;
    position: sticky;
    top: 47px;
    background-color: #e4e4e4;
}

.table-main-style .tr-meta-data td:first-child {
    font-weight: 500;
}

.table-main-style thead td {
    font-weight: 700;
}

.table-main-style .td-id {
    width: 200px;
}

.table-main-style .td-email {
    width: 300px;
}

.table-main-style .td-phone_no {
    width: 150px;
}

.table-main-style .td-role-access {
    width: 150px;
}

.table-main-style .td-menu {
    width: 170px;
}

.div-list-file-search {}

.div-list-file-search div {
    margin-bottom: 15px;
}

.div-list-file-search a p,
h5 {
    margin-bottom: 0;
}

.div-list-file-search p {
    color: black;
    font-size: 13px;
}

.div-list-file-search a:hover {
    text-decoration: unset;
}

.div-list-file-search a:hover h5 {
    text-decoration: underline;
}

.div-search-main .input-group {
    width: 600px;
}

.div-search-main .input-group .form-control {
    border-radius: 1.25rem 0 0 1.25rem;
    padding-left: 20px;
}

.div-search-main .input-group .input-group-text {
    cursor: pointer;
    border-radius: 0 1.25rem 1.25rem 0;
    background-color: white;
    width: 60px;
    padding: 0 20px;
}

.dms-modal {
    min-width: 98vw;
}

.modal-upload-file {
    /* min-width: 1500px; */
    /* min-width: 1300px; */
}

.modal-upload-file .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    min-height: 200px;
}

.modal-edit-file {
    /* min-width: 90vw; */
}

.modal-edit-file .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    min-height: 90%;
}

.modal-upload-file-detail {
    min-width: 1000px;
}

.modal-upload-file-detail .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    min-height: 200px;
}


.modal-confirm {
    min-width: 75vw;
}

.modal-confirm .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    min-height: 200px;
}

.share-file-modal {
    min-width: 70vw; 
}

.div-file-upload-list {}

.lbl-upload-file,
.lbl-import-excel,
.lbl-export-excel {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 3px 12px;
    margin-bottom: 0;
}

.lbl-upload-file-disable,
.lbl-import-excel-disable,
.lbl-export-excel-disable {
    cursor: no-drop;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 3px 12px;
    margin-bottom: 0;
    color: #bebebe;
}

.lbl-upload-file-employee {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 3px 12px;
    margin-bottom: 0;
}

.inp-upload-file,
.inp-import-excel,
.inp-export-excel {
    display: none;
}

.btn-manage-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #3397c3;
    border: 1px solid #3397c3;
}

.btn-manage-file:hover {
    color: #fff;
    background-color: #3397c3;
    border-color: #3397c3;
}

.btn-manage-file-disable {
    cursor: not-allowed;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #bebebe;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: sans-serif
}

.btn-export-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #28a745;
    border: 1px solid #28a745;
    font-family: sans-serif
}

.btn-export-file:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}


.btn-import-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #17a2b8;
    border: 1px solid #17a2b8;
    font-family: sans-serif
}

.btn-import-file:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-upload-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #ecbf38;
    border: 1px solid #ecbf38;
    font-family: sans-serif
}

.btn-upload-file:hover {
    color: #fff;
    background-color: #ecbf38;
    border-color: #ecbf38;
}




.btn-delete-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: red;
    border: 1px solid red;
    font-family: sans-serif
}

.btn-delete-file:hover {
    color: #fff;
    background-color: red;
    border-color: red;
}

.btn-delete-file-disable {
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #ffffff;
    color: grey;
    font-family: sans-serif
}

.btn-delete-file-hide {
    display: none !important;
}

.btn-copy-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #ecbf38;
    border: 1px solid #ecbf38;
    font-family: sans-serif
}

.btn-copy-file:hover {
    color: #fff;
    background-color: #ecbf38;
    border-color: #ecbf38;
}

.btn-edit-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #17a2b8;
    border: 1px solid #17a2b8;
    font-family: sans-serif
}

.btn-edit-file:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-edit-file-disable {
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #ffffff;
    color: grey;
    font-family: sans-serif
}

.btn-edit-file-hide {
    display: none !important;
}

.btn-share-file {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #00acfc;
    border: 1px solid #00acfc;
    font-family: sans-serif
}

.btn-share-file:hover {
    color: #fff;
    background-color: #00acfc;
    border-color: #00acfc;
}

.btn-share-file-disable {
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #ffffff;
    color: grey;
    font-family: sans-serif
}

.btn-add {
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #fff;
    color: #77B817;
    border: 1px solid #77B817;
}

.btn-add:hover {
    color: #fff;
    background-color: #77B817;
    border-color: #77B817;
}

.btn-add-disable {
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    padding: 3px 12px;
    margin-bottom: 0;
    background-color: #ffffff;
    color: grey;
}

.btn-add-hide {
    display: none !important;
}


.div-folder-main {
    padding-left: 10px;
    padding-top: 5px;
    height: 100%;
    max-height: calc(100vh - 120px);
    margin-bottom: 0rem;
    overflow-y: auto;
}


#div-modal-folder-manage .modal-body {}

.div-meta-role table tbody td {
    /*background-color: #f1f1f1;*/
}

.div-meta-role table .td-code {
    width: 100px;
}

.div-meta-role table .td-name {}

.div-meta-role table .td-update-on {
    width: 120px;
}

.div-meta-role table .td-update-by {
    width: 200px;
}

.div-meta-role table .td-menu {
    width: 170px;
}

.modal-file-detail,
.modal-file-meta-data {
    min-width: 80vw;
}

.modal-file-detail .modal-body .div-table,
.modal-file-meta-data .modal-body .div-table {
    overflow-y: auto;
    max-height: calc(100vh - 250px);
    min-height: 250px;
    padding-top: 0px;
}

.modal-file-detail .input-group {
    margin-bottom: 5px;
}

.modal-file-detail .input-group .input-group-prepend span {
    background-color: rgb(17 122 139 / 11%);
    color: rgb(17, 122, 139);
}

.table-file-meta-data,
.table-file-detail {
    border: 0px solid transparent;
}

.table-file-meta-data thead tr th,
.table-file-detail thead tr th {
    z-index: 2;
    position: sticky;
    top: 0;
    background-color: #ececec;
    border-top: 0px solid transparent;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.table-file-meta-data td {
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
}

.table-file-meta-data .td-id {
    width: 120px;
}

.table-file-meta-data tbody .td-id,
.table-file-detail tbody .td-header {
    font-weight: 500;
    background-color: #f6f6f6;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.table-file-detail .td-header {
    width: 300px;
}

.MuiTreeItem-content.makeStyles-content-2 .makeStyles-label-6:focus {
    background-color: #ececec;
}

.btn-reset-folder {
    width: 36px;
    height: 22px;
    padding: 0;
    border-radius: 11px;
    font-size: 12px;
}

.btn-file-explorer {
    width: 135px;
    height: 31px;
    padding: 0;
    font-size: 16px;
    font-family: sans-serif;
}

.btn-boxid {
    width: 100px;
    height: 31px;
    padding: 0;
    font-size: 16px;
    font-family: sans-serif;
}

.modal-upload-file .css-yk16xz-control {
    border-radius: 0px 3px 3px 0px;
}

.modal-edit-file .css-yk16xz-control {
    border-radius: 0px 3px 3px 0px;
}

.css-2613qy-menu {
    z-index: 1000;
}

.table-meta-data-key-vaue thead th,
.table-meta-data-key-vaue thead td {
    background-color: #ececec;
    border-top: 0px solid transparent;
    border-bottom: 2px solid rgba(202, 202, 202, 1);
}

.table-meta-data-key-vaue tbody .td-key {
    background-color: #f6f6f6;
    width: 200px;
}

.table-meta-data-key-vaue tbody .td-value {
    padding: 3px;
}

.trapezoid {
    border-bottom: 32px solid #3397c3;
    border-right: 25px solid transparent;
    height: 0;
    width: 200px;
    color: white;
    margin-top: 8px;
    min-width: 80px;
}

.trapezoid-purple {
    border-bottom: 32px solid #a665af;
}

.trapezoid>span {
    display: inline-block;
    color: white;
    font-size: 16px;
    /*font-weight: bold;*/
    padding: 4px 8px 8px 8px;
    vertical-align: middle;
}

.trapezoid-head {
    border-bottom: 32px solid #3397c3;
    border-right: 25px solid transparent;
    height: 0;
    width: 200px;
    color: white;
    margin-top: 8px;
}

.trapezoid-head>span {
    display: inline-block;
    color: white;
    font-size: 18px;
    padding: 8px 8px 8px 8px;
}

.custom-table {
    border-collapse: collapse;
    width: 100%;
    border: solid 1px #c0c0c0;
    font-family: sans-serif;
    font-size: 12px;
    background-color: #fff;
}

.custom-table th {
    padding: 8px;
    border: solid 1px #E7E7E7;
    font-weight: 200;
    vertical-align: middle;
}

.custom-table td {
    padding: 0px 4px 0px 4px;
    border: solid 1px #E7E7E7;
    font-weight: 200;
    vertical-align: middle;
}

.custom-table .td-header {
    font-size: 14px;
    font-weight: 600;
}


.custom-table>thead>tr {
    word-break: normal;
    background: #FFFFFF !important;
    color: #333 !important;
    font-size: 14px;
}

.custom-table>thead>tr>th {
    vertical-align: middle !important;
    background-color: #ffffff !important;
    font-weight: 600 !important;
}

.custom-table>thead>tr>th:not(.th-center) {
    text-align: left !important;
}

.custom-table tr:nth-child(odd) {
    background-color: #3397c314;
}

/*//////////////////////// theme table ///////////////////////////////////////*/
.theme-table {
    border-collapse: collapse;
    width: 100%;
    border: solid 1px #c0c0c0;
    font-family: sans-serif;
    font-size: 12px;
    background-color: #fff;
}

.theme-table th {
    padding: 8px;
    border: solid 1px #cccccc;
    font-weight: 200;
    vertical-align: middle;
    background-color: #FFFFFF
}

.theme-table td {
    padding: 0px 4px 0px 4px;
    border: solid 1px #cccccc;
    font-weight: 200;
    vertical-align: middle;
}

.theme-table .td-header {
    font-size: 14px;
    font-weight: 600;
}


.theme-table>thead>tr {
    word-break: normal;
    background: #FFFFFF !important;
    color: #333 !important;
    font-size: 14px;
}

.theme-table>thead>tr>th {
    vertical-align: middle !important;
    background-color: #eeeeee !important;
    font-weight: 600 !important;
}

.theme-table>thead>tr>th:not(.th-center) {
    text-align: left !important;
}

.theme-table tr:nth-child(odd) {
    background-color: #FFF;
}

.theme-table>tbody>tr>td {
    padding: 0.25rem 0.25rem;
}

/*//////////////////////// end theme table ///////////////////////////////////////*/

.th-center {
    text-align: center !important;
}

.td-center {
    text-align: center !important;
}

.td-left {
    text-align: left !important;
}

.td-right {
    text-align: right !important;
}

.navbar-font {
    font-family: sans-serif
}

.file-detail {
    font-family: sans-serif
}

.show-metadata {
    font-family: sans-serif
}

.metadata-group-font {
    font-family: sans-serif
}

.metadata-font {
    font-family: sans-serif
}

.role-font {
    font-family: sans-serif
}

.employee-font {
    font-family: sans-serif
}

.callback-font {
    font-family: sans-serif
}

.file-vision-font {
    font-family: sans-serif
}

.font-size-employee {
    font-size: 14px !important;
}

.modal-content-custom {
	min-height: 90vh;
    max-width: 90vw;
    margin: auto;
    margin-top: 3.5vh;
}

.modal-dialog-custom {
	max-height: 100vh;
    margin: 0;
    min-width: 100vw;
}