body {
    overflow-y: scroll;
}

.div-folder-main {
    overflow-x: hidden;
}

.btn-export-excel {
    border-color: #28a745 !important;
    background: #28a745 !important;
    text-align: center;
    color: #FFFFFF;
}


.folder-user-auth-modal-content {
    min-width: 70vw;
    min-height: 25vh;
    transform: translate(-25%, 25%);
}