.ts-selector-ul > li:not(:last-child) {
    border-bottom: solid 1px #aaaaaa;
}

.ts-bg-gray {
    background-color: #F3F4F6 !important;
}

.ts-bg-white {
    background-color: #FFFF !important;
}

.ts-bg-white:hover {
    background-color: rgb(255 255 255 / 0.05) !important;
}

.ts-bg-white:active {
    background-color: rgb(255 255 255 / 0.1) !important;
}

.accent-primary-900 {
    accent-color: #e55c00;
}

.bg-primary-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 92 0 / var(--tw-bg-opacity));
}

.ts-button-border {
    --tw-border-opacity: 1;
    border-color: rgb(229 92 0 / var(--tw-border-opacity));
    border-width: 1px;
    background-color: #FFFF;
}

.text-primary-900 {
    --tw-text-opacity: 1;
    color: rgb(229 92 0 / var(--tw-text-opacity));
}

.cursor-pointer {
    cursor: pointer;
}

.py-2 {
    padding-top: 0.5rem/* 8px */ !important;
    padding-bottom: 0.5rem/* 8px */ !important;
}

.px-2 {
    padding-left: 0.5rem/* 8px */ !important;
    padding-right: 0.5rem/* 8px */ !important;
}

.p-4 {
    padding: 1rem/* 16px */ !important;
}

.h-4 {
    height: 1rem/* 16px */ !important;
}

.w-4 {
    width: 1rem/* 16px */ !important;
}

.rounded-md {
    border-radius: 0.375rem/* 6px */;
}
