@media print {
    .pdf-container {
        display: none;
    }

    .react-pdf__Document {
        display: none;
    }

    .image-container {
        display: none;
    }

    .text-container {
        display: none;
    }
}

.image-container {
    /*position: absolute;*/
    top: 50%;
    left: 50%;
}

.div-disable-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-size: 16px;
}

.react-pdf__message {
    background: linear-gradient(125.75deg, white 0%, #dee4f0 33%, white 66%, #dee4f0 100%);
    background-size: 200% 100%;
    animation-name: loading-animate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    border-radius: 8px;
    max-width: 400px;
    width: 90vw;
    padding-bottom: 141.453%;
    font-size: 0;
}

.react-pdf__Document {
    border-radius: 8px;
    max-width: 100%;
    position: relative;
    background-color: white;
}

.react-pdf__Document:hover .page-controls,
#root:hover .page-controls {
    opacity: 1;
}


.react-pdf__Page {
    max-width: 400px;
    width: 90vw;
    display: contents !important;
}

.react-pdf__Page.rendering {
    position: absolute !important;
    opacity: 0;
    z-index: -1;
}

.react-pdf__Page.departuring {
    opacity: 0.5;
    transition: opacity ease-in-out 200ms;
}

.react-pdf__Page__svg {
    width: auto !important;
    height: auto !important;
    border-radius: 8px;
}

.react-pdf__Page__svg:empty {
    padding-bottom: 141.453%;
}

.react-pdf__Page__svg svg {
    border-radius: 8px;
    max-width: 400px;
    width: 90vw;
    height: auto;
    display: block;
    background-color: white;
}

.page-controls {
    position: fixed;
    bottom: 20px;
    /*position: absolute;*/
    bottom: 5%;
    left: 50%;
    background: white;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
    inline-size: max-content;
}

.page-controls span {
    font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
}

.page-controls button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
}

.page-controls button:enabled:hover {
    cursor: pointer;
}

.page-controls button:enabled:hover,
.page-controls button:enabled:focus {
    background-color: #e6e6e6;
}

.page-controls button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-controls button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.show-signed {
    position: fixed;
    top: 20px;
    padding: 7px;
    height: 60px;
    /*position: absolute;*/
    top: 10px;
    left: 10px;
    background: rgb(80, 200, 120);
    opacity: 0.9;
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
    inline-size: max-content;
}

.signed-data {
    display: inline-block;
    padding-left: 5px;
}

.Showcase .react-component {
    /*width: calc(100% - 10%);
    max-width: 1110px;*/
    margin-top: calc(var(--component-height) / -2);
    margin-bottom: 4em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-size: 19px;
}

.Showcase .react-component>* {
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
}

.Showcase main {
    margin-top: 4em;
}

.Showcase main section {
    padding: 4em 0;
}

.Showcase main section h1,
.Showcase main section h2,
.Showcase main section h3,
.Showcase main section h4,
.Showcase main section h5,
.Showcase main section h6 {
    margin-top: 0;
}

.Showcase main section .richContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -1em;
}

.Showcase main section .richContent>* {
    margin: 1em;
    max-width: 100%;
}

.Showcase main section .richContent .text {
    flex-basis: 400px;
    flex-grow: 1;
}

.Showcase main section .richContent .illustration {
    flex-basis: calc(100% - 10%);
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.Showcase main section .richContent .illustration img {
    width: 200px;
    height: 200px;
}

.Showcase main section:nth-of-type(odd) {
    background: #dee4f0;
}

.Showcase main section:nth-of-type(odd) .richContent .text {
    order: 1;
}

.Showcase main section:nth-of-type(odd) .richContent .illustration {
    order: 2;
}

@media screen and (min-width: 780px) {
    .Showcase main section:nth-of-type(even) .richContent .illustration {
        order: 1;
    }

    .Showcase main section:nth-of-type(even) .richContent .text {
        order: 2;
    }
}

@media screen and (min-width: 780px) {
    .Showcase main section .richContent .illustration {
        flex-basis: 300px;
    }
}

.Showcase .wrapper {
    width: calc(100% - 10%);
    max-width: 1110px;
    margin: 0 auto;
}

.Showcase .wrapper:after {
    content: '';
    display: block;
    clear: both;
}

.pdf-paging {
    position: fixed;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 100px;
    background-color: #7c7c7c;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.pdf-paging .pdf-thumnail {
    position: relative;
    border: 2px solid #888888;
    border-radius: 3px;
    margin-bottom: 1rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pdf-paging .pdf-thumnail.active {
    outline: 3px solid #0087fd;
    outline-offset: 3px;
}

.pdf-paging .pdf-thumnail .page-number {
    position: absolute;
    top: 0;
    right: 5px;
}

@media screen and (max-width: 650px) {
    .Showcase--react-date-picker .react-component {
        justify-content: flex-start;
    }
}